<template>
  <div id="language-selector" class="mt-1 z-index-99">
    <v-select @input="updateLocale" autofocus v-model="select" :items="items" item-text="lang" item-value="code" persistent-hint return-object></v-select>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  components: {},
  data() {
    return {
      locale: this.$i18n.locale,
      select: { lang: "EN", code: "en" },
      items: [
        { lang: "ES", code: "es" },
        { lang: "PT", code: "po" },
        { lang: "EN", code: "en" },
      ]
    };
  },
  computed: {},
  mounted() {
    this.$i18n.locale = localStorage.getItem("locale") || "es"
    this.select = {
      lang: this.$i18n.locale.toUpperCase(), code: this.$i18n.locale
    }
  },
  methods: {
    updateLocale(locale) {
      localStorage.setItem("locale", locale.code);
      this.$i18n.locale = locale.code;
    }
  }
};
</script>

<style lang="scss" scoped>
:not(.v-dialog) #language-selector{
  max-width: 60px;
}

.menuable__content__active {
  margin-top: 3em;
}
</style>
